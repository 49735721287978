<template>
  <div class="w-full min-h-[68px] p-2 flex items-center z-[1]">
    <ul
      class="max-md:hidden h-full flex-1 flex flex-wrap text-primary gap-x-4 px-4"
    >
      <li v-for="category in categories" :key="category.name" class="h-full">
        <NuxtLink
          class="block relative block pt-[11px] pb-[8px] border-b-[3px] border-transparent hover:border-neutral-light transition-all"
          :class="{
            '!border-neutral-light': route.path === category.path
          }"
          :to="category.path"
        >
          {{ category.name }}
        </NuxtLink>
      </li>
    </ul>
    <AppMenu
      :items="
        categories.map(({ name, path }) => ({
          text: name,
          to: path
        }))
      "
    >
      <AppButton
        class="!hidden icon-button variant-invisible text-primary h-12 w-12 min-w-12"
        :icon="mdiMenu"
        :aria-label="$t('common.menu')"
      />
    </AppMenu>
    <form
      id="blog-search-form"
      class="search-input flex-1 flex lg:max-w-[300px]"
      onsubmit="event.preventDefault()"
      @submit.prevent="triggerSearch"
    >
      <AppInput
        id="blog-search-input"
        v-model="searchValue"
        class="w-full variant-outline-lg"
        :placeholder="t('search.searchLabel')"
      >
      </AppInput>
      <AppButton
        type="submit"
        class="h-12 w-16 min-w-16 rounded-r -ml-px"
        :icon="mdiMagnify"
        :aria-label="$t('search.searchLabel')"
      >
      </AppButton>
    </form>
  </div>
</template>

<script setup lang="ts">
import { mdiMagnify, mdiMenu } from '@mdi/js';

const AppInput = defineAsyncComponent(
  () => import('@/async-components/App/Input.vue')
);
const AppMenu = defineAsyncComponent(
  () => import('@/async-components/App/Menu.vue')
);

const { t } = useI18n();

const localePath = useLocalePath();

const route = useRoute();

const props = defineProps({
  search: {
    type: String,
    default: ''
  },
  hideSearch: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits<{
  (e: 'trigger-search', value: string): void;
}>();

const searchValue = ref(props.search);

watch(
  () => props.search,
  value => {
    if (value === searchValue.value) return;
    searchValue.value = value;
  }
);

function triggerSearch() {
  emit('trigger-search', searchValue.value);
}

const categories = computed(() => {
  return [
    {
      name: t('blog.categories.present'),
      path: localePath(
        `/${t('routes.blog')}` + `/${t('routes.categories.present')}`
      )
    },
    {
      name: t('blog.categories.qualityOfLife'),
      path: localePath(
        `/${t('routes.blog')}` + `/${t('routes.categories.qualityOfLife')}`
      )
    },
    {
      name: t('blog.categories.investment'),
      path: localePath(
        `/${t('routes.blog')}` + `/${t('routes.categories.investment')}`
      )
    },
    {
      name: t('blog.categories.miamiTourism'),
      path: localePath(
        `/${t('routes.blog')}` + `/${t('routes.categories.miamiTourism')}`
      )
    }
  ];
});
</script>
